import { document, window } from '../globals';

export const withBaseUrl = (baseUrl?: string) => (url: string): string => {
    if (/http(s?):\/\//.exec(url)) {
        return url;
    }

    const defaultBaseUrl = window?.SITE_URL || document?.getElementsByTagName('base').item(0)?.href || '/';

    return (baseUrl ?? defaultBaseUrl) + ((url[0] === '/') ? url.substr(1) : url);
};

export const alterParamInSearchParams = (searchQuery: string, param: string, value: string): string => {
    const urlSearchParams = new URLSearchParams(searchQuery);
    urlSearchParams.set(param, value);
    return `?${urlSearchParams.toString()}`;
};

export const withSiteUrl = withBaseUrl();
